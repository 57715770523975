
import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import FileTable from './components/Files/';
import SuiDropzone from '../../components/SuiDropzone';
import FileLinks from '../../components/FileLinks/fileLinks';
// import Tables from '../../layouts/tables';

const Dashboard: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FileLinks includedLinks={['inputFolder', 'organizedDocuments']} />
      <FileTable />
      <Footer />
    </DashboardLayout>
  );
};

export default Dashboard;
