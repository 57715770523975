import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_SERVER } from '../config/constant';

const axios: AxiosInstance = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

axios.interceptors.request.use(
  (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    return Promise.resolve(config);
  },
  (error: any): Promise<never> => Promise.reject(error)
);

axios.interceptors.response.use(
  (response: AxiosResponse): Promise<AxiosResponse> =>
    Promise.resolve(response),
  (error: any): Promise<never> => {
    return Promise.reject(error);
  }
);

export default axios;
