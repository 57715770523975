import axios from './index'; // Ensure this imports your configured Axios instance

interface AwsApiResponse {
  data: any;
  status: number;
}

class AwsApi {
  static base: string = '/aws';
  static fileFromS3(fileId: string, token: string): Promise<AwsApiResponse> {
    return axios.get(`${this.base}/fileFromS3/`, {
      params: { id: fileId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default AwsApi;
