import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuth } from './auth-context/auth.context';
import SweetAlert from 'react-bootstrap-sweetalert';

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  const { user } = useAuth();
  if (!user || !user.refreshToken || user.refreshToken === '') {
    return (
      <SweetAlert
        title="You must be signed in!"
        onCancel={() => history.push('/authentication/sign-in')}
        onConfirm={() => history.push('/authentication/sign-in')}
        confirmBtnCssClass="px-5"
      />
    );
  }

  return <Route {...rest} />;
};
