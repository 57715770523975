// DocumentApi.ts
import axios from './index';
import EntityApi from './entityApi';

interface DocumentData {
  [key: string]: any;
}

class DocumentApi {
  static base = '/documents';

  static renameFiles(folderURL: string): Promise<any> {
    const folderID = folderURL.match(/[^/]+$/)?.[0] || '';

    const requestBody = {
      folderID: folderID,
      refreshToken: JSON.parse(localStorage.getItem('user') || '{}')
        .refreshToken,
    };

    return axios.post(`${this.base}/renameFiles`, requestBody, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem('user') || '{}').refreshToken
        }`,
        'Content-Type': 'application/json',
      },
    });
  }

  static uploadFile({
    fileName,
    file,
    daUploader,
    token,
  }: {
    fileName: string;
    file: File;
    daUploader: string;
    token: string;
  }): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('daUploader', daUploader);
    formData.append('fileName', fileName);

    return axios.post(`${this.base}/uploadFile`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static analyzeDocument(
    documentId: string,
    token: string,
    documentType: string
  ): Promise<any> {
    return axios.put(
      `${this.base}/analyzeDocument/?id=${documentId}&documentType=${documentType}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  static getDocumentsByType(
    type: string,
    token: string,
    limit: number
  ): Promise<any> {
    return axios.get(
      `${this.base}/getDocumentsByType/?type=${type}&limit=${limit}`,
      {
        headers: { Authorization: `${token}` },
      }
    );
  }

  static updateDocument(data: DocumentData, token: string): Promise<any> {
    return axios.put(`${this.base}/updateDocument/`, data, {
      headers: { Authorization: `${token}` },
    });
  }

  static createDocumentType(
    data: { type: string },
    token: string
  ): Promise<any> {
    return axios.post(`${this.base}/createDocumentType`, data, {
      headers: { Authorization: `${token}` },
    });
  }

  static deleteDocument(id: string, token: string): Promise<any> {
    return axios.delete(`${this.base}/${id}`, {
      headers: { Authorization: `${token}` },
    });
  }

  static downloadDocument(token: string): Promise<any> {
    return axios.get(`excel/export-to-sheets/`, {
      headers: { Authorization: `${token}` },
      responseType: 'blob',
    });
  }

  static getFullDocumentAttributes(type: string, token: string): Promise<any> {
    return axios.get(`${this.base}/getDocumentTypes/?type=${type}`, {
      headers: { Authorization: `${token}` },
    });
  }

  static getAttributesByDocumentType(
    type: string,
    token: string
  ): Promise<any> {
    return axios.get(`${this.base}/getAttributesByDocumentType/?type=${type}`, {
      headers: { Authorization: `${token}` },
    });
  }

  static getDTSAsByDocumentType(type: string, token: string): Promise<any> {
    return axios.get(`${this.base}/getDTSAsByDocumentType/?type=${type}`, {
      headers: { Authorization: `${token}` },
    });
  }

  static async getGeneralFieldOptions(
    generalField: string,
    token: string
  ): Promise<string[]> {
    let response;
    switch (generalField) {
      case 'diaType':
        response = await axios.get(`${this.base}/getDocumentTypeOptions`, {
          headers: { Authorization: `${token}` },
        });
        return response.data;
      case 'diaRecipient':
      case 'diaSender':
        response = await axios.get(`${this.base}/getAllEntities`, {
          headers: { Authorization: `${token}` },
        });
        return response.data.map((item: { name: string }) => item.name);
      default:
        response = await axios.get(
          `${this.base}/getAllEntities?type=${generalField}`,
          {
            headers: { Authorization: `${token}` },
          }
        );
        console.log(response.data);
        return response.data.map((item: { name: string }) => item.name);
    }
  }

  static async addGeneralFieldOption(
    type: string,
    value: string,
    token: string
  ): Promise<any> {
    switch (type) {
      case 'diaType':
        return await DocumentApi.createDocumentType({ type: value }, token);
      default:
        return await EntityApi.createEntity({ name: value, type: type }, token);
    }
  }

  static processNewFiles(token: string, userEmail: string): Promise<any> {
    const requestBody = {
      refreshToken: token,
      userEmail: userEmail,
    };

    return axios.post(`${this.base}/processNewFiles`, requestBody, {
      headers: {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      },
    });
  }

  static processDTSAValues({
    documentId,
    objectToFill,
    token,
  }: {
    documentId: string;
    objectToFill: object;
    token: string;
  }): Promise<any> {
    return axios.post(
      `${this.base}/processDTSAValues`,
      { documentId, objectToFill },
      {
        headers: { Authorization: `${token}` },
      }
    );
  }

  static downloadExcel(token: string): Promise<any> {
    return axios.get(`excel/export-to-excel?refreshToken=${token}`, {
      responseType: 'json',
    });
  }

  static downloadStatement(token: string, documentId: string): Promise<any> {
    return axios.get(
      `excel/export-statement-to-excel?documentId=${documentId}`,
      {
        responseType: 'arraybuffer',
      }
    );
  }
}

export default DocumentApi;
