/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from './context';

import { AuthProvider } from './auth-context/auth.context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import User from './types/user';

const userString = localStorage.getItem('user');
const userData: User = userString ? JSON.parse(userString) : null;

ReactDOM.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <AuthProvider userData={userData}>
        <GoogleOAuthProvider clientId="180540666532-o7mhli0j285pok2hrkfbl4l22upnc79v.apps.googleusercontent.com">
            <App />
        </GoogleOAuthProvider>
      </AuthProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
