/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, ReactNode } from 'react';

// @mui material components
import Typography from '@mui/material/Typography';

// clsx is a utility for constructing className strings conditionally
import clsx from 'clsx';

// Custom styles for SuiTypography
import styles from './styles';

interface SuiTypographyProps {
  textColor?: 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark' | 'text' | 'white';
  fontWeight?: false | 'light' | 'regular' | 'medium' | 'bold';
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign?: 'unset' | 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
  textGradient?: boolean;
  opacity?: number;
  customClass?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline';
  component?: React.ElementType; // Add component prop
  children: ReactNode;
  to?: string; // Add 'to' prop
  href?: string; // Add 'href' prop
  [key: string]: any; // Accept any other props
  onClick?: () => void; // Add 'onClick' prop
}

const SuiTypography = forwardRef<HTMLSpanElement, SuiTypographyProps>(
  (
    {
      textColor = 'dark',
      fontWeight = false,
      textTransform = 'none',
      verticalAlign = 'unset',
      textGradient = false,
      opacity = 1,
      customClass = '',
      variant = 'body1', // Default variant
      component = 'span', // Default component
      children,
      to, // Destructure 'to' prop
      href, // Destructure 'href' prop
      onClick, // Destructure 'onClick' prop
      ...rest
    },
    ref
  ) => {
    const classes = styles({
      textColor,
      textTransform,
      verticalAlign,
      opacity,
    });

    return (
      <Typography
        {...rest}
        ref={ref}
        variant={variant}
        component={component}
        className={clsx(classes.suiTypography, customClass, {
          [classes[`suiTypography_${fontWeight}`]]: fontWeight,
          [classes.suiTypography_textTransform]: textTransform,
          [classes.suiTypography_verticalAlign]: verticalAlign,
          [classes.suiTypography_textGradient]: textGradient,
        })}
        to={to} 
        href={href} 
        onClick={onClick} 
        {...rest} 
      >
        {children}
      </Typography>
    );
  }
);

export default SuiTypography;
