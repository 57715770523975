import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import SuiBox from '../../../../components/SuiBox';
import SuiTypography from '../../../../components/SuiTypography';
import Card from '@mui/material/Card';
import DocumentViewer from '../DocumentViewer';
import Button from '@mui/material/Button';


import AddIcon from '@mui/icons-material/Add';

// Styling for tables
import styles from '../../../../layouts/tables/styles';
import DocumentApi from '../../../../api/documentApi';
import { useAuth } from '../../../../auth-context/auth.context';
import { AttachFile, Refresh } from '@mui/icons-material';
import Drive from '../../../../api/drive';
import CircularProgress from '@mui/material/CircularProgress';
import { DocumentSchema } from '../../../../types/document';
import { getColumns } from './tableData';
import { sortRows } from '../../../../utils/utils';
import { DocumentStatusEnum } from '../../../../utils/DocumentStatusEnum';
import { DriveFilesStatus } from '../../../../utils/DriveFilesStatus';
import { DocumentTypeEnum } from '../../../../utils/DocumentTypeEnum';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FilesTable: React.FC<{ documentType?: string }> = ({ documentType = null }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [rows, setRows] = useState<DocumentSchema[]>([]);
  const columns = getColumns(rows, setSelectedIndex);
  const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const { user } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const classes = styles();

  useEffect(() => {
    const fetchData = async () => {
      // get the folder paths from the google drive
      const newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    };
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    fetchData();

    intervalRef.current = setInterval(async () => {
      let newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [user]);

  interface DriveFiles {
    status: string;
    numDocumentsLeft: string | number;
  }
  
  const [newDriveFiles, setNewDriveFiles] = useState<DriveFiles>({
    status: DriveFilesStatus.loading,
    numDocumentsLeft: 0,
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // get the folder paths from the google drive
      const newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    };
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    fetchData();

    intervalRef.current = setInterval(async () => {
      let newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [user]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const limit = 1000000;
      const response = await DocumentApi.getDocumentsByType(documentType, user.refreshToken ,limit);

      if (response) {
        let newRows = response.data.map((doc, index) => ({
          ...doc,
          id: doc._id || index,
        }));
        if(documentType !== 'statement') {
          newRows = newRows.filter(row => row.diaType != 'statement');
        }
        setRows(sortRows(newRows));
      }
    };

    fetchDocuments();
  }, [update]);

  useEffect(() => {
    if (selectedIndex === -1) {
      setUpdate(!update);
    }
  }, [selectedIndex]);

  const uploadFiles = async (files: FileList) => {
    console.log(files);
    if (!files) return;

    let uploadingRows: DocumentSchema[] = [];
    const startRowIndex = rows.length;

    Array.from(files).forEach((file, index) => {
      const newRow: DocumentSchema = {
        id: startRowIndex + index,
        daFileName: file.name,
        diaType: 'Default',
        daUploader: user.userEmail,
        daStatus: DocumentStatusEnum.UPLOADING,
      };
      uploadingRows.push(newRow);
    });
    let allRows = [...uploadingRows, ...rows]; // append new rows to the start of the table
    setRows(sortRows(allRows));

    //upload each file, then analyze it
    Array.from(files).forEach(async (file, index) => {
      try {
        let document = await DocumentApi.uploadFile({
          fileName: file.name,
          file: file,
          daUploader: user.userEmail,
          token: user.refreshToken,
        });
        allRows[index].daStatus = DocumentStatusEnum.ANALYZING;
        setRows(sortRows(allRows)); // update status
        await DocumentApi.analyzeDocument(
          document.data.documentId,
          user.refreshToken,
          documentType || '',
        );
        setUpdate(!update);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    });
  };

  const processDocuments = async (): Promise<void> => {
    // Clear the interval to pause it
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    setNewDriveFiles({
      status: 'loading...',
      numDocumentsLeft: newDriveFiles.numDocumentsLeft,
    });
    console.log(user);
    try {
      await DocumentApi.processNewFiles(user.refreshToken, user.userEmail);
    } catch (error: any) {
      if (error.response.status === 409) {
        // processing, do nothing
      }
    }
    setNewDriveFiles({
      status: 'Processing',
      numDocumentsLeft: newDriveFiles.numDocumentsLeft,
    });
    //wait 5 seconds
    await new Promise(resolve => setTimeout(resolve, 5000));
    // get the new drive files
    const newDriveFilesResponse = await Drive.getNewDriveFiles(
      user.refreshToken,
      user.userEmail
    );
    console.log(newDriveFilesResponse.data);
    setNewDriveFiles(newDriveFilesResponse.data);

    // Restart the interval after processing
    intervalRef.current = setInterval(async () => {
      let newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setUpdate(!update);
      setNewDriveFiles(newDriveFilesResponse.data);
    }, 5000);
  };

  const confirmDone = async (file: DocumentSchema) => {
    file.daStatus = DocumentStatusEnum.DONE;
    const updatedRows = [...rows];
    const fileIndex = updatedRows.findIndex(row => row.id === file.id);
    if (fileIndex !== -1) {
      updatedRows[fileIndex] = file;
      setRows(sortRows(updatedRows));
    }

    if (documentType !== DocumentTypeEnum.STATEMENT) {
      //go to next 'review required' file, wrapping around to start if needed
      let startIndex = (selectedIndex + 1) % rows.length;
      let i = startIndex;
      do {
        if (rows[i].daStatus === DocumentStatusEnum.REVIEW_REQUIRED) {
          setSelectedIndex(i);
          return;
        }
        i = (i + 1) % rows.length;
      } while (i !== startIndex);
      //no review required files found after checking all
      setSelectedIndex(-1);
    }
  };


  const handleDownloadExcel = async () => {
    setDownloading(true);
    try {
      const response = await DocumentApi.downloadExcel(user.refreshToken);
      let reponse = response.data.link;
      setDownloadLink(reponse);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <SuiBox py={3}>
      <DocumentViewer
        file={rows[selectedIndex]}
        numFiles={rows.length}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        confirmDone={() => confirmDone(rows[selectedIndex])}
        documentType={documentType}
      />
      <Card>
        <SuiBox display="flex" alignItems="center" p={3}>
          <SuiTypography variant="h6">Recent Files</SuiTypography>
          <Button component="label" color="secondary" startIcon={<AddIcon />}>
            Upload {documentType ? documentType : 'file'}
            <VisuallyHiddenInput
              ref={fileInputRef}
              type="file"
              onChange={e => uploadFiles(e.target.files)}
              multiple
              />
            </Button>
          
          { !documentType &&
          <>

          <Button
            component="label"
            color="secondary"
            startIcon={<AttachFile />}
            onClick={processDocuments}
            disabled={
              newDriveFiles.status === DriveFilesStatus.loading ||
              newDriveFiles.status === DriveFilesStatus.processing
            }
          >
          {newDriveFiles.status === DriveFilesStatus.loading ? 'Loading...' :
           newDriveFiles.status === DriveFilesStatus.processing ? `Processing... ${newDriveFiles.numDocumentsLeft} left` :
           `Process ${newDriveFiles.numDocumentsLeft} Docs from Drive`}

            {(newDriveFiles.status === DriveFilesStatus.processing ||
              newDriveFiles.status === DriveFilesStatus.loading) && <CircularProgress size={20} />}
          </Button>
          <Button
            component="a"
            onClick={handleDownloadExcel}
          >
            {downloading ? 'Saving excel on your google drive' : 'Export to Excel'}
          </Button>

          {downloadLink && !downloading && (
            <span style={{ marginLeft: '10px' }}>
              <a href={downloadLink} target="_blank" rel="noopener noreferrer">
                {downloadLink}
              </a>
            </span>
            )}
          </>
          } 
        </SuiBox>
        
        <SuiBox className={classes.tables_table}>
        <DataGrid
  rows={rows}
  columns={columns}
/>
        </SuiBox>
      </Card>
    </SuiBox>
  );
};

export default FilesTable;
