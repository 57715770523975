import React, { useEffect, useRef, useState } from 'react';

import { useAuth } from '../../auth-context/auth.context';
import Drive from '../../api/drive';
import SuiBox from '../SuiBox';
import SuiTypography from '../SuiTypography';

interface FolderPaths {
  documentsData: string;
  inputFolder: string;
  organizedDocuments: string;
}

interface DriveFiles {
  status: string;
  numDocumentsLeft: string | number;
}


function FileLinks({
  includedLinks = ['inputFolder', 'organizedDocuments', 'documentsData'],
}: {
  includedLinks?: string[];
}): JSX.Element {
  const spinAnimation = {
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
  };
  const { user } = useAuth();
  const [folderPaths, setFolderPaths] = useState<FolderPaths>({
    documentsData: 'loading...',
    inputFolder: 'loading...',
    organizedDocuments: 'loading...',
  });
  const [newDriveFiles, setNewDriveFiles] = useState<DriveFiles>({
    status: 'loading...',
    numDocumentsLeft: 'loading...',
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // get the folder paths from the google drive
      const folderPathsResponse = await Drive.getFoldersPaths(
        user.refreshToken
      );
      const folderPaths = folderPathsResponse.data;
      setFolderPaths(folderPaths);

      // get the new drive files
      const newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    };
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    fetchData();
  }, []);

  return (
        <SuiBox
        style={{
        display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: '20px',
            }}
        >
        {includedLinks.includes('inputFolder') && <SuiBox style={{ display: 'flex', gap: '10px' }}>
        <SuiTypography variant="body1">Input Folder:</SuiTypography>
        <a href={folderPaths.inputFolder} target="_blank" rel="noreferrer">
            {folderPaths.inputFolder}
        </a>
        </SuiBox>}
        {includedLinks.includes('organizedDocuments') && <SuiBox style={{ display: 'flex', gap: '10px' }}>
        <SuiTypography variant="body1">Organized Documents:</SuiTypography>
        <a
            href={folderPaths.organizedDocuments}
            target="_blank"
            rel="noreferrer"
        >
            {folderPaths.organizedDocuments}
        </a>
        </SuiBox>}
        {includedLinks.includes('documentsData') && <SuiBox style={{ display: 'flex', gap: '10px' }}>
        <SuiTypography variant="body1">Documents Data:</SuiTypography>
        <a href={folderPaths.documentsData} target="_blank" rel="noreferrer">
            {folderPaths.documentsData}
        </a>
        </SuiBox>}
    </SuiBox>
  );
}

export default FileLinks;
