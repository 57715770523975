import { DocumentSchema } from '../types/document';
import { Field } from '../types/field';
export const convertObjectToList = (data: Record<string, any>): Field[] => {
  let fields: Field[] = [];
  //make each key into a label
  Object.keys(data).forEach((key) => {
    fields.push({ name: key, value: data[key] });
  });
  return fields;
};

/**
 * find object in list by key value and return the specified key value
 * @param list Array of objects to search through
 * @param filterKey Key to filter objects by
 * @param filterValue Value to match against filterKey
 * @param returnKey Key whose value should be returned from matching object
 * @returns Value of returnKey from matching object, or null if no match found
 */
export const findByObjectKeyValueInList = <T extends Record<string, any>>(
  list: T[],
  filterKey: keyof T,
  filterValue: any,
  returnKey: keyof T
): T[keyof T] | null => {
  let obj = list.find((obj) => obj[filterKey] === filterValue);
  return obj ? obj[returnKey] : null;
};

/**
 * convert file to base64
 * @param file
 * @returns base64 string
 */
export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
  });
};

const statusPriority: Record<string, number> = {
  Uploading: 1, // Highest priority
  Analyzing: 2,
  'Review Required': 3,
  Done: 4, // Lowest priority
};

export const sortRows = (newRows: DocumentSchema[]): DocumentSchema[] => {
  let sortableRows = Array.from(newRows);
  sortableRows.sort((a, b) => {
    let statusCompare =
      (statusPriority[a.daStatus] || 5) - (statusPriority[b.daStatus] || 5);
    if (statusCompare === 0) {
      return (
        new Date(b.daUploadDate).getTime() - new Date(a.daUploadDate).getTime()
      );
    }
    return statusCompare;
  });
  return sortableRows;
};
