export const DocumentStatusEnum = {
  UPLOADING: 'Uploading',
  ANALYZING: 'Analyzing',
  DONE: 'Done',
  REVIEW_REQUIRED: 'Review Required',
};

export const DocumentStatusColors = {
  [DocumentStatusEnum.UPLOADING]: 'warning', // Orange
  [DocumentStatusEnum.ANALYZING]: 'secondary', // Royal Blue
  [DocumentStatusEnum.DONE]: 'success', // Lime Green
  [DocumentStatusEnum.REVIEW_REQUIRED]: 'error', // Red Orange
};
