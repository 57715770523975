import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const flash = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const FlashingBox = () => (
  <Box
    sx={{
      width: '100%',
      height: '36px', // Adjust this to match your input height
      animation: `${flash} 1s ease-in-out infinite`,
      borderRadius: '4px',
    }}
  />
);

export default FlashingBox;
