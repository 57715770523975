import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import SuiBox from '../../../components/SuiBox';
import SuiInput from '../../../components/SuiInput';
import SuiCreatableSelect from '../../../components/SuiCreatableSelect';
import SuiTypography from '../../../components/SuiTypography';
import DocumentApi from '../../../api/documentApi';
import { AttributeTypeEnum } from '../../../utils/AttributeTypeEnum';
import { FileDataItem } from '../../../types/fileDataItem';
import { FileData } from '../../../types/fileData';
import FlashingBox from './FlashingBox';
import IconButton from '@mui/material/IconButton';
import { DownloadOutlined } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';

interface PDFInfoDisplayProps {
  user: {
    refreshToken: string;
  };
  fileData: FileData;
  fileId: string;
  setFileData: (data: FileData) => void;
  setMadeUnsavedChanges: (changed: boolean) => void;
  processNewFields: ({diaType}: {diaType: string}) => Promise<void>;
  documentType: string;
  downloadStatement: () => Promise<void>;
  isSaving: boolean;
}

const PDFInfoDisplay: React.FC<PDFInfoDisplayProps> = ({
  user,
  fileData,
  fileId,
  setFileData,
  setMadeUnsavedChanges,
  processNewFields,
  documentType,
  downloadStatement,
  isSaving
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const divStyle = {
    marginTop: '16px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  useEffect(() => {
    setIsLoading(true);
    // Add a cleanup function to handle component unmounting
    return () => setIsLoading(false);
  }, [fileId]);

  useEffect(() => {
    // When fileData changes, we can assume the data is ready
    setIsLoading(false);
  }, [fileData]);

  const handleStatementChange = (index: number, field: 'transactionDate' | 'transactionDetails' | 'amount', value: string) => {
    const newData = { ...fileData };
    newData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE][index][field] = value;
    setFileData(newData);
    setMadeUnsavedChanges(true);
  };

  const handleChange = async (index: number, value: string, documentType: string): Promise<void> => {
    setMadeUnsavedChanges(true);
    const newData = { ...fileData };
    if (fileData[documentType][index].name === 'diaType') {
      let oldDIAType = fileData[documentType][index].value;
      if (oldDIAType != value) {
        processNewFields({
          diaType: value
        });
      }
    }
    if (
      fileData[documentType][index].inputType === 'dropdown' &&
      fileData[documentType][index].options &&
      !fileData[documentType][index].options.includes(value)
    ) {
      const newAttribute = newData[documentType];
      newAttribute[index].options?.push(value);
      newData[documentType] = newAttribute;
    }
    newData[documentType][index].value = value;
    setFileData(newData);
  };

  const renderStatementSpecificFields = (item: FileDataItem, index: number, identifier: string = "statement") => {
    return (
      <tr key={`${fileId}-${identifier}-${index}`}>
        <td>
          <SuiInput
            key={`${fileId}-date-${index}`}
            type="text"
            placeholder="MM/DD/YYYY"
            value={item.transactionDate || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleStatementChange(index, 'transactionDate', event.target.value)
            }
            aria-label={item.transactionDate}
          />
        </td>
        <td>
          <SuiInput
            key={`${fileId}-details-${index}`}
            value={item.transactionDetails || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleStatementChange(index, 'transactionDetails', event.target.value)
            }
            aria-label={item.transactionDetails}
          />
        </td>
        <td>
          <SuiInput
            key={`${fileId}-amount-${index}`}
            value={item.amount || ''}
            type="number"
            step="0.01"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleStatementChange(index, 'amount', event.target.value)
            }
            aria-label={item.id}
          />
        </td>
      </tr>
    );
  };

  const renderListItem = (item: FileDataItem, index: number) => {
    return (
      <SuiBox
        key={fileId + "-" + item.key}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="8px"
      > 
      
        {item.inputType != 'hidden' && (
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            style={{ fontSize: '16px' }}
        >
            {item.label}
          </SuiTypography>
        )}
        <div style={{ width: '60%' }}>
          {item.loading && (
            <FlashingBox />
          )}
          {!item.loading && (
            <>
            {item.inputType === 'dropdown' && (
              <> 
                <SuiCreatableSelect
                  success={!item.options?.includes(item.value)}
                  onChange={({ value }: { value: string }) =>
                    handleChange(index, value, item.attributeType)
                  }
                  onCreateOption={async (value: string) => {
                    let type = item.entityType || item.name;
                    await DocumentApi.addGeneralFieldOption(
                      type,
                      value,
                      user.refreshToken
                    );
                    handleChange(index, value, item.attributeType);
                  }}
                  options={item.options?.map((option) => ({
                    label: option,
                    value: option,
                  })) || []}
                  value={{ label: item.value, value: item.value }}
                />
              </>
            )}
            {item.inputType === 'editable' && (
              <>
                  <SuiInput
                    defaultValue={item.value || ''}
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                      handleChange(index, event.target.value, item.attributeType)
                    }
                    aria-label={item.label}
                />
              </>
            )}
            {item.inputType == 'disabled' && (
              <>
                {item.value.startsWith('https') ? (
                  <a href={item.value} target="_blank" rel="noopener noreferrer">
                    <SuiTypography
                      variant="caption"
                      fontWeight="bold"
                      style={{ fontSize: '16px', color: 'blue', textDecoration: 'underline' }}
                    >
                      {item.value}
                    </SuiTypography>
                  </a>
                ) : (
                  <SuiTypography
                    key={fileId + "-" + item.key}
                    variant="caption"
                    fontWeight="bold"
                    style={{ fontSize: '16px' }}
                  >
                    {item.value}
                  </SuiTypography>
                )}
              </>
            )}
            </>
          )}
        </div>
      </SuiBox> 
    );
  };

  const [page, setPage] = React.useState(1);
  const itemsPerPage = 50;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <SuiBox key={fileId} width="100%" overflowY="auto">
      {isLoading ? (
        <SuiBox display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </SuiBox>
      ) : (
        <>
          {fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].length !== 0 && <div style={divStyle}> Document General Attributes</div>}
          {fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].map((item, index) =>
            renderListItem(item, index)
          )}

          {fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].length !== 0 && <div style={divStyle}> Document Identity Attributes</div>}
          {fileData[AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE].map(
            (item, index) => renderListItem(item, index)
          )}

          {documentType != 'statement' ?
          <SuiBox>
            {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length !== 0 && 
              <div style={divStyle}> Document Specific Attributes </div>
            }
            {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].map(
              (item, index) => renderListItem(item, index)
            )}
          </SuiBox>
          : 
          <SuiBox>
            <div style={{...divStyle}}>
              <div>Statement Attributes</div>
              {isSaving ? <CircularProgress /> :
              <IconButton
                onClick={downloadStatement}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
              >
                <DownloadOutlined />
              </IconButton>
              }
            </div>
            <table style={{ width: '100%', marginBottom: '8px' }}>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '60%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Transaction Details</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((item, index) => renderStatementSpecificFields(item, index))}
              </tbody>
            </table>
            <SuiBox display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={Math.ceil(fileData[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE].length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </SuiBox>
          </SuiBox>
          }
        </>
      )}
    </SuiBox>
  );
};

export default PDFInfoDisplay;
function processNewFields(arg0: { diaType: string; }) {
  throw new Error('Function not implemented.');
}

