import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import FilesTable from "../dashboard/components/Files";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";

function Transactions(): JSX.Element {
    return <DashboardLayout>
        <DashboardNavbar />
        <FilesTable documentType="statement" />
        <Footer />
    </DashboardLayout>;
}

export default Transactions;
