/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, ReactNode } from 'react';
import { ButtonProps } from '@mui/material/Button';
import Button from '@mui/material/Button';
import clsx from 'clsx';

// Custom styles for SuiButton
import styles from './styles';

interface SuiButtonProps extends Omit<ButtonProps, 'color' | 'variant' | 'size'> {
  buttonColor?: 'white' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  variant?: 'text' | 'contained' | 'outlined' | 'gradient';
  size?: 'small' | 'medium' | 'large';
  circular?: boolean;
  iconOnly?: boolean;
  children: ReactNode;
  customClass?: string;
  animate?: string;
}

const muiColorMap: Record<string, ButtonProps['color']> = {
  primary: 'primary',
  secondary: 'secondary',
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

const SuiButton = forwardRef<HTMLButtonElement, SuiButtonProps>(
  (
    {
      buttonColor = 'white',
      variant = 'contained',
      size = 'medium',
      circular = false,
      iconOnly = false,
      children,
      customClass = '',
      animate = '',
      ...rest
    },
    ref
  ) => {
    const classes = styles({ buttonColor, variant, size, iconOnly, animate });
    return (
      <Button
        {...rest}
        ref={ref}
        color={muiColorMap[buttonColor]}
        variant={variant === 'gradient' ? 'contained' : variant}
        size={size}
        className={clsx(classes[variant], customClass, {
          [classes.circular]: circular,
          [classes.iconOnly]: iconOnly,
          [classes.animate]: animate,
        })}
      >
        {children}
      </Button>
    );
  }
);

SuiButton.displayName = 'SuiButton';

export default SuiButton;
