import React, { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PDFViewer from './PDFViewer';
import type { DocumentSchema } from '../../../types/document';
import Drive from '../../../api/drive';
import { useAuth } from '../../../auth-context/auth.context';
import PDFInfoDisplay from './PDFInfoDisplays';
import DocumentApi from '../../../api/documentApi';
import { convertObjectToList } from '../../../utils/utils';
import { AttributeTypeEnum } from '../../../utils/AttributeTypeEnum';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // "Previous" button
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // "Next" button
import { FileData } from '../../../types/fileData';
import AccountApi from '../../../api/accountApi';
import { AccountConfig } from '../../../types/accountConfig';
import SuiBox from '../../../components/SuiBox';
import SuiTypography from '../../../components/SuiTypography';
import SuiAlert from '../../../components/SuiAlert';
import AwsApi from '../../../api/aws';
import { mockStatementDocumentType } from '../../../types/statement';
import { DocumentStatusColors } from '../../../utils/DocumentStatusEnum';
import { DocumentTypeEnum } from '../../../utils/DocumentTypeEnum';

interface DocumentViewerPageProps {
  file: DocumentSchema;
  numFiles: number;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  confirmDone: () => void;
  documentType: string;
}

const DocumentViewerPage: React.FC<DocumentViewerPageProps> = ({
                                                                 file,
                                                                 numFiles,
                                                                 selectedIndex,
                                                                 setSelectedIndex,
                                                                 confirmDone,
                                                                 documentType = '',
                                                               }) => {
  /* handle the screen layout */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [fileData, setFileData] = useState<FileData>({
    [AttributeTypeEnum.DOCUMENT_ATTRIBUTE]: [],
    [AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]: [],
    [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: [],
  });
  const {user} = useAuth(); // Use the useAuth hook to get the current user
  const [madeUnsavedChanges, setMadeUnsavedChanges] = useState<boolean>(false);
  const [suggestedFilePath, setSuggestedFilePath] = useState<string>('');
  const [suggestedFileName, setSuggestedFileName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  
  const pdfViewerUrl = useMemo(() => file?.url, [file?.url]);

  useEffect(() => {
    console.log(fileData);
  }, [fileData]);

  /* for overflowed scroll bars */
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  useEffect(() => {
    setFilePathAndFileName();
  }, [fileData]);

  useEffect(() => {
    const populateDocumentFields = async () => {
      
      if (selectedIndex >= 0 && file) {
       if(!file.url ) {
        const s3Response = await AwsApi.fileFromS3(file.id, user.refreshToken);
        if (s3Response.data.Body) {
            const blob = new Blob([new Uint8Array(s3Response.data.Body.data)], {
            type: 'application/pdf',
          });
          file.url = URL.createObjectURL(blob);
        }
        }
        let allDocumentAttributes: any = [];
        let defaultAttributes: any[] = [];
        let specificAttributes: any[] = [];
          let dIATypeOptions = file['diaType'];
          allDocumentAttributes =
            await DocumentApi.getAttributesByDocumentType(
              dIATypeOptions,
              user.refreshToken
            );
          allDocumentAttributes = allDocumentAttributes.data;
          defaultAttributes = allDocumentAttributes?.default?.fields || [];
          specificAttributes = allDocumentAttributes?.requestedType?.fields || [];
        if(documentType === DocumentTypeEnum.STATEMENT) { // only specific attributes are needed for statements
          //only keep status field for statements
          defaultAttributes = defaultAttributes.filter(e => (
            e.name === 'daStatus' ||
            e.name === 'daFileName' ||
            e.name === 'fileSaveLocation'
          ));
        }

          const filledAttributes = convertObjectToList(file);
          const allAttributes = [...defaultAttributes, ...specificAttributes]

          const newFileData = await buildFieldMatching(
            allAttributes,
            filledAttributes
          );
          setFileData(newFileData);
          setIsVisible(true);
        }     
    };

    populateDocumentFields();
  }, [file, selectedIndex, user.refreshToken]);

  const setFilePathAndFileName = async (): Promise<void>   => {
        //set suggested file path and file name

        let fileNameSchema: object[] = [];
        let filePathSchema: object[] = [];

        let fileTypeSchema = await DocumentApi.getAttributesByDocumentType(documentType, user.refreshToken);
        if(fileTypeSchema.data.requestedType) {
          let response = fileTypeSchema.data.requestedType;
          fileNameSchema = response.fileNameSchema;
          filePathSchema = response.filePathSchema;
        }
        let accountConfigResponse = await AccountApi.getAccountConfig(user.refreshToken);
        let accountConfig: AccountConfig = accountConfigResponse.data;
        fileNameSchema = fileNameSchema.length > 0 ? fileNameSchema : accountConfig.fileNameSchema;
        filePathSchema = filePathSchema.length > 0 ? filePathSchema : accountConfig.filePathSchema;

        //get suggested file path and file name
        let aSuggestedFileName: string[] = [];
        let aSuggestedFilePath: string[] = [];

        Object.keys(fileData).forEach(key => {
          for(let j = 0; j < fileNameSchema.length; j++) {
            let fileNameField = fileData[key].find(e => e.name === fileNameSchema[j]['field']);

            // Check if the name field is 'diaRecipient' and it's empty replace it with diaRecipient ( FOR MARC)
            if (fileNameSchema[j]['field'] === 'diaRecipient ID' && (!fileNameField || fileNameField.value === '')) {
              fileNameField = fileData[key].find(e => e.name === 'diaRecipient');
            } 
            
            let filePathField = fileData[key].find(e => e.name === filePathSchema[j]['field']);

            if (filePathSchema[j]['field'] === 'diaRecipient ID' && (!filePathField || filePathField.value === '')) {
              filePathField = fileData[key].find(e => e.name === 'diaRecipient');
            } 

            if (fileNameField && fileNameField?.value !== '') {
              aSuggestedFileName.push(fileNameField?.value);
            } else if (fileNameField && fileNameField?.value === '' ) {
              aSuggestedFileName.push('Unspecified_' + fileNameField.label   );

            }

            if (filePathField && filePathField?.value !== '') {
              aSuggestedFilePath.push(filePathField?.value);
            }else if (filePathField && filePathField?.value === '' ) {
              aSuggestedFilePath.push('Unspecified_' + filePathField.label   );
            }
          }
        });
        aSuggestedFilePath = aSuggestedFilePath.map(e => e.replace(/\//g, '-'));
        aSuggestedFileName = aSuggestedFileName.map(e => e.replace(/\//g, '-'));
        setSuggestedFileName(aSuggestedFileName.join('_'));
        setSuggestedFilePath(aSuggestedFilePath.join('//'));
  }

  const buildFieldMatching = async (allAttributes: any[], filledAttributes: any[]): Promise<FileData> => {
    const completeAttributes = initiateNewFileData();
    for (let i = 0; i < allAttributes.length; i++) {
      const field = {
        key: i,
        label: allAttributes[i].label,
        name: allAttributes[i].name,
        inputType: allAttributes[i].inputType,
        attributeType: allAttributes[i].attributeType,
        value: '',
        options: [],
        loading: false,
        entityType: '',
      };
      if (allAttributes[i].inputType === 'dropdown') {
        const options = await DocumentApi.getGeneralFieldOptions(
          allAttributes[i].entityType || allAttributes[i].name,
          user.refreshToken
        );
        field.entityType = allAttributes[i].entityType;
        field.options = options;
      }
      for (let j = 0; j < filledAttributes.length; j++) {
        if (
          allAttributes[i]?.name &&
          allAttributes[i]?.name === filledAttributes[j]?.name
        ) {
          field.value = filledAttributes[j].value;
          break;
        }
      }
      completeAttributes[field.attributeType].push(field);
    }
    if(documentType === DocumentTypeEnum.STATEMENT) {
      let transactions = filledAttributes.find(e => e.name === 'transactions');
      completeAttributes[AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE] = transactions.value;
    }
    return completeAttributes;
  };

  const warnMadeUnsavedChanges = (): boolean => {
    if (madeUnsavedChanges) {
      setMadeUnsavedChanges(false);
        return window.confirm(
          'You have unsaved changes. Are you sure you want to continue?'
        );
      }
    return true;
  };

  const warnUnsaved = (): boolean => {
    if(!(fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(e => e.name === 'daStatus')?.value === 'Done' && !madeUnsavedChanges)) {
      window.confirm('Confirm before extracting');
      return false;
    }
    return true;
  }

  const showNextPDF = (): void => {
    if (warnMadeUnsavedChanges()) {
      const newIndex = (selectedIndex + 1 + numFiles) % numFiles;
      setSelectedIndex(newIndex);
    }
  };

  const showPrevPDF = (): void => {
    if (warnMadeUnsavedChanges()) {
      const newIndex = (selectedIndex - 1 + numFiles) % numFiles;
      setSelectedIndex(newIndex);
    }
  };

  const clearPDFs = (): void => {
    if (warnMadeUnsavedChanges()) {
      console.log('Clearing PDFs');
      setFileData(initiateNewFileData());
      setSelectedIndex(-1);
      setIsVisible(!isVisible);
    }
  };
  const initiateNewFileData = (): FileData => {
    return {
      [AttributeTypeEnum.DOCUMENT_ATTRIBUTE]: [],
      [AttributeTypeEnum.DOCUMENT_IDENTITY_ATTRIBUTE]: [],
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: [],
    };
  };

  const handleConfirmUpload = async (): Promise<void> => {
    setIsSaving(true);
    // Find and update the document status field to 'Done'
    setMadeUnsavedChanges(false);
    setFileData(prevData => {
      const newData = {...prevData};
      const daStatusField = newData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(
        field => field.name === 'daStatus'
      );
      if (!daStatusField) {
        newData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].push({
          name: 'daStatus',
          value: 'Done'
        });
      } else {
        daStatusField.value = 'Done';
      }
      setIsSaving(false);
      return newData;
    });

    const updatedFileData: any[] = convertObjectToList(fileData);
    console.log(updatedFileData);
    if(documentType === DocumentTypeEnum.STATEMENT) {
      let transactions = {};
      transactions['transactions'] = updatedFileData.find(e => e.name === 'DTSA')['value'];
      updatedFileData.find(e => e.name === 'DTSA')['value'] = transactions;
    }
    console.log(updatedFileData);

    try {
      let documentType = file.diaType;
      let existingTypes = await DocumentApi.getGeneralFieldOptions(
        'diaType',
        user.refreshToken
      );
      if (!existingTypes.includes(documentType)) {
        await DocumentApi.createDocumentType(
          {type: documentType},
          user.refreshToken
        );
      }

      updatedFileData.unshift({
        defaultDocumentId: file._id
      });
      await DocumentApi.updateDocument(updatedFileData , user.refreshToken);
      Drive.saveDocument({ // not waiting for this to finish so we can go to next page
        refreshToken: user.refreshToken,
        documentId: file._id,
        destinationFilePath: suggestedFilePath,
        newName: suggestedFileName
      });
      if(documentType !== DocumentTypeEnum.STATEMENT) {
        confirmDone();
      }

      //make file done
    } catch (error: any)  {
      console.error('Error uploading file:', error);
      alert(`Error during file upload: ${error.message}`);
    }
  };

  const processNewFields = async ({
    diaType,
  }: {
    diaType: string
  }): Promise<void> => {

    const dTSAs = await DocumentApi.getDTSAsByDocumentType(
      diaType,
      user.refreshToken
    );
    let fields = dTSAs.data.fields;


    setFileData(prevFileData => ({
      ...prevFileData,
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: fields
    }));
    let objectToFill = {};
    for (let i = 0; i < fields.length; i++) {
      objectToFill[fields[i].name] = "";
    }

    for (let i = 0; i < fields.length; i++) {
      fields[i].loading = true;
    }

    setFileData(prevFileData => ({
      ...prevFileData,
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: fields
    }));

    let suggestionsResponse = await DocumentApi.processDTSAValues({
      documentId: file._id,
      objectToFill, 
      token: user.refreshToken
    });
    let suggestions = suggestionsResponse.data;

    for (let i = 0; i < fields.length; i++) {
      fields[i].value = suggestions[fields[i].name];
      if (fields[i].inputType === 'dropdown') {
        fields[i].options = await DocumentApi.getGeneralFieldOptions(
          fields[i].value,
          user.refreshToken
        );
      }
      fields[i].loading = false;
    }

    console.log(fields);

    setFileData(prevFileData => ({
      ...prevFileData,
      [AttributeTypeEnum.DOCUMENT_TYPE_SPECIFIC_ATTRIBUTE]: fields
    }));
  }

  const downloadStatement = async () => {
    if (warnUnsaved()) {   
      let response = await DocumentApi.downloadStatement(user.refreshToken, file._id);
      console.log(response);

      const excelData = new Uint8Array(response.data);
      const blob = new Blob([excelData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  }



  return (
    <div>
      {isVisible && file && (
        <>
          <div
            style={{
              display: 'flex',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10000,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(255,255,255)',
            }}
          >
            <div style={{width: '80%', height: '100%'}}>
              <PDFViewer url={pdfViewerUrl} />
            </div>

            <div
              style={{
                width: '55%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <div
                style={{
                  flex: 1,
                  overflowY: 'auto',
                  position: 'relative',
                  padding: '20px',
                }}
              >
                {isLoading ? (
                  <CircularProgress/>
                ) : (
                  fileData && (
                    <PDFInfoDisplay
                      user={user}
                      fileData={fileData}
                      fileId={file._id}
                      setFileData={setFileData}
                      setMadeUnsavedChanges={setMadeUnsavedChanges}
                      processNewFields={processNewFields}
                      documentType={documentType}
                      downloadStatement={downloadStatement}
                      isSaving={isSaving}
                    />
                  )
                )}
              </div>
              <SuiBox
                key={file._id + " " + fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(e => e.name === 'daStatus')?.value}
              >
                <SuiAlert
                  color={DocumentStatusColors[fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(e => e.name === 'daStatus')?.value]}
                  dismissible={false}
                  icon="check"
                  title="New Files"
                  style={{
                    textAlign: 'center',
                  }}
                >
                    <SuiTypography
                      variant="caption"
                      fontWeight="bold"
                      style={{
                        color: 'white',
                      }}
                    >
                      {fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(e => e.name === 'daStatus')?.value}
                    </SuiTypography>
                </SuiAlert>
              </SuiBox>
              <SuiBox>
                <SuiBox
                  variant="caption"
                  fontWeight="bold"

                  style={{
                    padding: '5px 20px',
                    fontSize: '16px',
                    borderTop: '1px solid #ccc',
                  }}
                >
                  <SuiBox
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    File Path
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      style={{ 
                        fontSize: '16px',
                        marginLeft: '10px',
                        // marginRight: '50px'
                      }}
                    >
                      Organized/{suggestedFilePath.split('//').join('/')}
                    </SuiTypography>
                  </SuiBox>
                  <SuiBox
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                      File Name
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      style={{ fontSize: '16px' }}
                    >
                      {suggestedFileName}
                    </SuiTypography>

                  </SuiBox> 
                </SuiBox>
                <SuiBox
                  style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px',
                  borderTop: '1px solid #ccc',
                  backgroundColor: 'rgba(255,255,255,0.65)',
                }}
              >
                <Button onClick={showPrevPDF} startIcon={<ArrowBackIosIcon/>}>
                  Previous
                </Button>
                <span
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    color: '#005DB4',
                  }}
                >
                  {`${selectedIndex + 1} of 
                  ${numFiles}
                  `}
                </span>
                <Button onClick={showNextPDF} endIcon={<ArrowForwardIosIcon/>}>
                  Next
                </Button>
              </SuiBox>

              <SuiBox
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  padding: '10px',
                  backgroundColor: 'rgba(255,255,255,0.65)',
                }}
              >
                <Button
                  variant="contained"
                  onClick={clearPDFs}
                  style={{
                    background: '#7E7F80',
                    color: 'white',
                    borderRadius: 15,
                    padding: '5px 25px',
                  }}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={fileData[AttributeTypeEnum.DOCUMENT_ATTRIBUTE].find(e => e.name === 'daStatus')?.value === 'Done' && !madeUnsavedChanges}
                  onClick={handleConfirmUpload}
                  style={{
                    background: '#005DB4',
                    color: 'white',
                    borderRadius: 15,
                    padding: '5px 25px',
                  }}
                >
                  Confirm
                  </Button>
                </SuiBox>
              </SuiBox>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentViewerPage;
