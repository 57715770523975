/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from 'react';
import { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import clsx from 'clsx';

// Custom styles for SuiBox
import styles from './styles';

interface SuiBoxProps extends BoxProps {
  backgroundColor?: string;
  backgroundGradient?: boolean;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  boxShadow?: string;
  customClass?: string;
  [key: string]: any;
}

const SuiBox = forwardRef<HTMLDivElement, SuiBoxProps>(
  (
    {
      backgroundColor = 'transparent',
      backgroundGradient = false,
      color = 'dark',
      opacity = 1,
      borderRadius = 'none',
      boxShadow = 'none',
      customClass = '',
      ...rest
    },
    ref
  ) => {
    const classes = styles({
      backgroundColor,
      color,
      opacity,
      borderRadius,
      boxShadow,
    });

    return (
      <Box
        ref={ref}
        className={clsx(classes.suiBox, customClass, {
          [classes.suiBox_backgroundGradient]: backgroundGradient,
        })}
        {...rest}
      />
    );
  }
);

SuiBox.displayName = 'SuiBox';

export default SuiBox;
