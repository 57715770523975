import React, { useEffect, useRef, useState } from 'react';

import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import DocumentApi from '../../api/documentApi';
import SuiButton from '../../components/SuiButton';
import { useAuth } from '../../auth-context/auth.context';
import Drive from '../../api/drive';
import SuiBox from '../../components/SuiBox';
import SuiTypography from '../../components/SuiTypography';
import { Refresh } from '@mui/icons-material';
import FileLinks from '../../components/FileLinks/fileLinks';

interface FolderPaths {
  documentsData: string;
  inputFolder: string;
  organizedDocuments: string;
}

interface DriveFiles {
  status: string;
  numDocumentsLeft: string | number;
}


function SheetsLab(): JSX.Element {
  const spinAnimation = {
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(360deg)' },
    },
  };
  const { user } = useAuth();
  const [newDriveFiles, setNewDriveFiles] = useState<DriveFiles>({
    status: 'loading...',
    numDocumentsLeft: 'loading...',
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      // get the folder paths from the google drive
      const newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    };
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    fetchData();

    intervalRef.current = setInterval(async () => {
      let newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [user]);

  const processDocuments = async (): Promise<void> => {
    // Clear the interval to pause it
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    setNewDriveFiles({
      status: 'loading...',
      numDocumentsLeft: newDriveFiles.numDocumentsLeft,
    });
    console.log(user);
    try {
      await DocumentApi.processNewFiles(user.refreshToken, user.userEmail);
    } catch (error: any) {
      if (error.response.status === 409) {
        // processing, do nothing
      }
    }
    setNewDriveFiles({
      status: 'Processing',
      numDocumentsLeft: newDriveFiles.numDocumentsLeft,
    });
    //wait 5 seconds
    await new Promise(resolve => setTimeout(resolve, 5000));
    // get the new drive files
    const newDriveFilesResponse = await Drive.getNewDriveFiles(
      user.refreshToken,
      user.userEmail
    );
    console.log(newDriveFilesResponse.data);
    setNewDriveFiles(newDriveFilesResponse.data);

    // Restart the interval after processing
    intervalRef.current = setInterval(async () => {
      let newDriveFilesResponse = await Drive.getNewDriveFiles(
        user.refreshToken,
        user.userEmail
      );
      setNewDriveFiles(newDriveFilesResponse.data);
    }, 5000);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <FileLinks />

      <h3 style={{ textAlign: 'center' }}>
        Input Google Drive Folder URL to rename files
      </h3>
      <SuiBox
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <SuiButton
          onClick={processDocuments}
          disabled={
            newDriveFiles.status === 'loading...' ||
            newDriveFiles.status === 'Processing'
          }
        >
          {newDriveFiles.status === 'loading...'
            ? 'Loading...'
            : newDriveFiles.status === 'Processing'
            ? 'Processing...'
            : 'Process Docs from Google Drive'}
          <Refresh
            style={{ marginLeft: '10px' }}
            sx={
              newDriveFiles.status === 'Processing' ||
              newDriveFiles.status === 'loading...'
                ? spinAnimation
                : {}
            }
          />
        </SuiButton>
      </SuiBox>
      <SuiBox style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <SuiTypography variant="h6">
          {newDriveFiles.numDocumentsLeft} documents left
        </SuiTypography>
      </SuiBox>

      <Footer />
    </DashboardLayout>
  );
}

export default SheetsLab;
