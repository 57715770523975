import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import SuiButton from "../../../../components/SuiButton";
import moment from "moment";


interface ButtonProps {
    disabled: boolean;
    color: 'warning' | 'secondary' | 'error' | 'success';
    variant: 'contained';
}

export const getColumns = (rows: any[], setSelectedIndex: (index: number) => void): GridColDef[] => {
    return [
        { field: 'daFileName', headerName: 'name', width: 400 },
        { field: 'diaType', headerName: 'type', width: 150 },
        { field: 'daUploader', headerName: 'uploader', width: 150 },
        {
      field: 'daUploadDate',
      headerName: 'upload date',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const formattedDate = moment(params.value).format('MMMM D, YYYY');
        return <div>{formattedDate}</div>;
      },
    },
    {
      field: 'daStatus',
      headerName: 'Status',
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const buttonProps: Record<string, ButtonProps> = {
          Uploading: {
            disabled: true,
            color: 'warning',
            variant: 'contained',
          },
          Analyzing: {
            disabled: true,
            color: 'secondary',
            variant: 'contained',
          },
          'Review Required': {
            disabled: false,
            color: 'error',
            variant: 'contained',
          },
          Done: {
            disabled: false,
            color: 'success',
            variant: 'contained',
          },
        };
        const { disabled, color, variant } = buttonProps[
          params.row.daStatus as keyof typeof buttonProps
        ] || { disabled: true, color: 'secondary', variant: 'contained' };
        const onClick = (e: React.MouseEvent) => {
          e.stopPropagation(); // Prevent row selection
          setSelectedIndex(rows.findIndex((row) => row.id === params.id));
        };
        return (
          <SuiButton
            buttonColor={color}
            disabled={disabled}
            variant={variant}
            onClick={onClick}
          >
            {params.row.daStatus}
          </SuiButton>
        );
      },
    },
    ] as GridColDef[];
}