// accountApi.ts
import axios from './index';
import User from '../types/user';

class AccountApi {
  static base = '/account';

  static createAccount(data: User, token: string): Promise<any> {
    return axios.post(`${this.base}/createAccount`, data, {
      headers: { Authorization: `${token}` },
    });
  }

  static getAccountConfig(token: string): Promise<any> {
    return axios.get(`${this.base}/getAccountConfig`, {
      headers: { Authorization: `${token}` },
    });
  }
}

export default AccountApi;
