import React from 'react';

interface PDFViewerProps {
  url?: string; // Make url optional
  style?: React.CSSProperties;
}

const PDFViewer: React.FC<PDFViewerProps> = React.memo(({ url, style }) => {
  
  const iframeStyle: React.CSSProperties = {
    width: '100%',
    height: '100vh', 
    border: 'none',
    ...style,
  };

  return (
    <>
      {url ? (
        <iframe
          style={iframeStyle}
          src={`${url}#view=Fit`} // #view=Fit helps to fit doc to the bounds
          title="PDF Viewer"
          name="pdf-viewer"
        ></iframe>
      ) : (
        <div style={{ ...iframeStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p>Loading...</p>
        </div>
      )}
    </>
  );
});

export default PDFViewer;