import React, { useState, ChangeEvent } from 'react';

import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import DocumentApi from '../../api/documentApi';
import SuiInput from '../../components/SuiInput';
import SuiButton from '../../components/SuiButton';

const RenamingLab: React.FC = (): JSX.Element => {
  const [folderURL, setFolderURL] = useState<string>('');

  const renameFiles = async (): Promise<void> => {
    const test = await DocumentApi.renameFiles(folderURL);
    console.log(test);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setFolderURL(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h3 style={{ textAlign: 'center' }}>
        Input Google Drive Folder URL to rename files
      </h3>
      <div style={{ display: 'flex', gap: '10px' }}>
        <SuiInput
          placeholder="folder URL"
          name="name"
          onChange={handleInputChange}
        />
        <SuiButton onClick={renameFiles}>Submit</SuiButton>
      </div>
      <Footer />
    </DashboardLayout>
  );
};

export default RenamingLab;
